.m-listwithimage {
  @extend %ctype-base;
}

.m-mainnavigation {
  @include media-breakpoint-up(lg) {
    display: flex;
    gap: 25px;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 10px;
  }
}

.m-mainnavigation__wrapper {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: $dusk_blue;
  transform: translateY(100%);
  padding: var(--bs-gutter-x) 33px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  height: calc(100vh - 93px);
  overflow: auto;
  &.is-active {
    @include media-breakpoint-down(lg) {
      opacity: 1;
      pointer-events: all;
      animation: fadeInFromRight 0.2s ease-in-out;
    }
  }

  @include media-breakpoint-up(lg) {
    position: unset;
    height: unset;
    transform: unset;
    opacity: unset;
    pointer-events: unset;
    background-color: unset;
    padding: unset;
    pointer-events: all;
  }
}

.m-mainnavigation__submenuwrapper[data-level='1'] {
  .m-mainnavigation__link {
    @extend %body;
    margin-bottom: 16px;
  }

  @include media-breakpoint-down(lg) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: var(--bs-gutter-x);
    background: $dusk_blue;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
    z-index: 2;
    overflow: auto;
    &.is-active {
      opacity: 1;
      pointer-events: all;
      animation: fadeInFromRight 0.2s ease-in-out;
    }
  }

  @include media-breakpoint-up(lg) {
    $container-width: map-get($container-max-widths, lg);
    left: 0;
    bottom: 0;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(260px, 262px));
    transform: translateY(calc(100% - 10px));
    width: 100vw;
    padding: 48px max(15px, calc(((100% - $container-width) / 2) + 15px));
    background: $dusk_blue;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
    color: $white;
    max-height: calc(100vh - 250px);
    overflow: auto;

    &:hover {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.m-mainnavigation__submenuwrapper[data-level='2'] {
  @include media-breakpoint-down(lg) {
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    & > div {
      overflow: hidden;
    }
    &.is-active {
      grid-template-rows: 1fr;
      margin-bottom: 16px;
    }
  }
}

.m-mainnavigation__itemwrapper {
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;

  @include media-breakpoint-up(lg) {
    &[data-level='1']:hover {
      background-color: $dusk_blue;
      border-color: $white;
      color: $white;
      .m-mainnavigation__item {
        color: $white;
        &.active {
          border-color: $white;
        }
      }
    }
    &.has-children:hover {
      background-color: $dusk_blue;

      .m-mainnavigation__item {
        color: $white;
        &.active {
          border-color: $white;
        }
      }
      .m-mainnavigation__submenuwrapper {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}

.m-mainnavigation__item,
.m-mainnavigation__link {
  @extend %menu;
  @include media-breakpoint-down(lg) {
    color: $white;
  }
}

.m-mainnavigation__subitem {
  @include media-breakpoint-down(lg) {
    color: $white;
  }
}

.m-mainnavigation__item {
  padding-bottom: 2px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.2s ease-in-out;

  @include media-breakpoint-up(lg) {
    &.active {
      border-bottom: 2px solid black;
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 16px 0;
    border-bottom: 1px solid $white;
    display: flex;
    justify-content: space-between;
  }
}

.m-mainnavigation__submenu-toggle {
  display: none;
}

.has-children .m-mainnavigation__submenu-toggle {
  mask-image: url('../images/icons/icon-arrow.svg');
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
  height: 24px;
  width: 24px;
  background-color: $white;
  display: block;
  cursor: pointer;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.m-mainnavigation__item:hover {
  .m-mainnavigation__link {
    color: $white;
    text-decoration: none;
  }
}

.m-mainnavigation__itemwrapper {
  @include media-breakpoint-up(lg) {
    padding: 10px;
  }
}

.m-mainnavigation__subitemwrapper[data-level='2'] {
  & > .m-mainnavigation__subitem {
    @extend %submenu;
    text-transform: uppercase;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid $white;
    & > .m-mainnavigation__link {
      @extend %submenu;
      text-transform: uppercase;
      margin: 0;
    }

    @include media-breakpoint-up(lg) {
      border-bottom: 2px solid $white;
      min-height: 66px;
      display: flex;
      align-items: flex-end;
    }

    @include media-breakpoint-down(lg) {
      display: flex;
      justify-content: space-between;
      .m-mainnavigation__link {
        margin-bottom: 0;
      }
    }
  }
}

[data-level='3'] .m-mainnavigation__subitem {
  margin-bottom: 16px;
}

.m-mainnavigation__submenu-back {
  @extend %link;
  color: $white;
  display: flex;
  align-items: center;
  margin-bottom: 36px;
  cursor: pointer;
  &::before {
    content: url('../images/icons/icon-arrow-left-white.svg');
    margin-right: 10px;
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.m-mainnavigation__subitem__menu-toggle {
  mask-image: url('../images/icons/icon-chevron.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
  height: 24px;
  width: 24px;
  flex: 0 0 24px;
  background-color: $white;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  &.is-active {
    transform: rotate(180deg);
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}
