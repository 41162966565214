//TODO: copied from BIM, still needs to be adjusted for this project

/**
 * Variables
*/

/* Colors */
$greige: #baa885;
$greige-60: #d6cbb6;
$greige-30: #ebe5db;
$white: #fff;
$black: #000;
$dusk-blue: #4c5f76;
$dusk-blue-30: #c9cfd6;
$myrtle-green: #3c7a74;
$myrtle-green-60: #8ab0ac;
$myrtle-green-30: #c5d7d6;
$moss-green: #759c52;
$moss-green-60: #adc498;
$moss-green-30: #d6e2cb;
$teal-blue: #0097a7;
$teal-blue-60: #66c1cb;
$teal-blue-30: #b2e0e4;
$glacier-blue: #8bb4d2;
$glacier-blue-60: #b9d2e4;
$glacier-blue-30: #dce8f1;
$plum: #99577c;
$plum-60: #c19ab0;
$plum-30: #e1cdd8;
$chestnut-red: #c94034;
$chestnut-red-60: #df8d86;
$chestnut-red-30: #efc6c2;
$orange: #e4650e;
$orange-60: #efa36f;
$orange-30: #f7d1b7;
$dark-gold: #c38a2b;
$dark-gold-60: #dbb980;
$dark-gold-30: #eddcc0;
$yellow: #f5c61b;
$yellow-60: #f9dc76;
$yellow-30: #fceeba;
$dusk-blue-60: #939fac;
$greige-10: #f8f6f3;

/* Base sizes */
$base-em: 18;

/* Font weights */
$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;

/* Body font size */
$body-font-size-px: 18;
$body-font-size: percentage(
  calc(($body-font-size-px / $base-em) * 100 / 100)
); // in percent

/* Colors */
$white: #ffffff;
$grey: #706a68;
$grey-2: #f6f6f6;
$grey-3: #888;
$black: #000000;

$main-text-color: #242428;
$main-text-color-opacity-005: rgba(#242428, 0.05);

/* Spacing */
$spacing-xs: 4px;
$spacing-s: 8px;
$spacing-m: 12px;
$spacing-l: 24px;
$spacing-xl: 48px;
$spacing-xxl: 60px;

// $green: #59B5A0;
// $light-blue: #5A79B4;
// $dark-blue-counter-text: #154194;

$roboto_condensed: 'RobotoCondensed', sans-serif;
$roboto: 'Roboto', sans-serif;
$bitter: 'Bitter', serif;
