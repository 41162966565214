.m-cardsthreecolumns {
  @extend %ctype-base;
}

.m-cardsthreecolumns__container {
  @include make-container();
  @include media-breakpoint-up(lg) {
    max-width: map-get($container-max-widths, lg);
  }
}

.m-cardsthreecolumns__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: var(--bs-gutter-x, 30px);
  row-gap: $spacing-xl;
}

.m-cardsthreecolumns__item--title {
  margin-bottom: $spacing-l;
}

.m-cardsthreecolumns__item--link {
  background: $greige-10;
  padding: 24px 16px 53px 16px;
  height: 100%;
  width: 100%;
  display: block;
  transition: all 0.3s ease-in-out;
  &:hover {
    text-decoration: none;
    color: unset;
    background: $plum-30;
  }

  @include media-breakpoint-up(lg) {
    padding: 24px 20px 40px 20px;
  }
}

.m-cardsthreecolumns__item--readmore {
  @extend %arrow-link;
}

.m-cardsthreecolumns__headline {
  margin-bottom: $spacing-l;
}

.m-cardsthreecolumns__parent-link--wrapper {
  padding-top: $spacing-xl;
}

.m-cardsthreecolumns__parent-link {
  @extend %arrow-link;
}
