.m-anchor-navigation{
  @extend %ctype-base;

  position: sticky;
  top: 182px;
}
.m-anchor-navigation__link {
  @extend %body;
  background-color: $greige-10;
  font-family: 'RobotoCondensed';
  display: inline-block;
  padding: 7px 11px 5px;
  line-height: normal;
  margin-top: 1px;
  &:first-child {
    margin-top: 0px;
  }
  &:hover {
    background-color: $dark-gold-30;
    color: $black;
    text-decoration: none;
    cursor: pointer;
  }
}
