$grid-gutter-width: 30px; //Colum
$container-padding-x: $grid-gutter-width; //Container paddingn padding
$gutters: $grid-gutter-width;

$max-width: 1140px;
$min-width: 338px;

$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 992px,
  xl: 1170px,
);

$container-max-widths: (
  xs: calc(100% - $grid-gutter-width),
  sm: calc(100% - $grid-gutter-width),
  md: calc(100% - $grid-gutter-width),
  lg: $max-width + $grid-gutter-width,
  xl: $max-width + $grid-gutter-width,
);
