.wzb-newsletterForm {
  position: relative;
  padding: $spacing-xl 0;
  &::before {
    content: '';
    background-color: $greige-30;
    display: block;
    height: 100%;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  input {
    display: block;
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 0;
    outline: none;
    max-width: 750px;
  }

  input[type='checkbox'] {
    @extend %checkbox;
  }

  input[type='checkbox'] + label {
    @extend %label;
    position: relative;
    padding-left: 30px;
    &::before {
      position: absolute;
      left: 0;
    }
    display: block;
    a {
      @extend %label;
      display: inline;
      text-decoration: underline;
    }
  }

  .wzb-form__button {
    @extend %link;
    @extend %arrow-link;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: $spacing-xl;
  }
}
