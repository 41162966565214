$sprites: (
    'ic-chevron-left': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cdefs%3e%3cpath id='a' d='M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12z'/%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd'%3e%3cmask id='b' fill='white'%3e%3cuse xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='%23a'/%3e%3c/mask%3e%3cg mask='url(%23b)' fill='black'%3e%3cpath d='M-13-13h50v50h-50z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e",
    'ic-launch': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cdefs%3e%3cpath d='M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z' id='a'/%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd'%3e%3cmask id='b' fill='white'%3e%3cuse xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='%23a'/%3e%3c/mask%3e%3cg mask='url(%23b)' fill='black'%3e%3cpath d='M-13-13h50v50h-50z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e",
    'ic-search-white': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cdefs%3e%3cpath d='M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z' id='a'/%3e%3c/defs%3e%3cg fill='white' fill-rule='evenodd'%3e%3cmask id='b'%3e%3cuse xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='%23a'/%3e%3c/mask%3e%3cg mask='url(%23b)'%3e%3cpath d='M-13-13h50v50h-50z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e",
    'ic-search': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cdefs%3e%3cpath d='M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z' id='a'/%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd'%3e%3cmask id='b' fill='white'%3e%3cuse xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='%23a'/%3e%3c/mask%3e%3cg mask='url(%23b)' fill='black'%3e%3cpath d='M-13-13h50v50h-50z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e",
    'icon-arrow-black': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cdefs%3e%3cpath id='a' d='M0 5h14.17l-3.58-3.59L12 0l6 6-6 6-1.41-1.41L14.17 7H0z'/%3e%3c/defs%3e%3cuse fill='___color___' xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='%23a' transform='translate(3 6)' fill-rule='evenodd'/%3e%3c/svg%3e",
    'icon-arrow-left-white': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cdefs%3e%3cpath id='a' d='M0 5h14.17l-3.58-3.59L12 0l6 6-6 6-1.41-1.41L14.17 7H0z'/%3e%3c/defs%3e%3cuse fill='white' xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='%23a' transform='rotate(180 10.5 9)' fill-rule='evenodd'/%3e%3c/svg%3e",
    'icon-arrow': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cdefs%3e%3cpath id='a' d='M0 5h14.17l-3.58-3.59L12 0l6 6-6 6-1.41-1.41L14.17 7H0z'/%3e%3c/defs%3e%3cuse fill='%23262626' xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='%23a' transform='translate(3 6)' fill-rule='evenodd'/%3e%3c/svg%3e",
    'icon-bluesky': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath d='M5.769 3.934C8.29 5.827 11.004 9.667 12 11.727c.996-2.06 3.709-5.9 6.231-7.793C20.051 2.568 23 1.51 23 4.874c0 .672-.385 5.644-.611 6.451-.785 2.807-3.647 3.523-6.192 3.09 4.449.757 5.58 3.265 3.136 5.773-4.642 4.764-6.672-1.195-7.192-2.722-.096-.28-.14-.41-.141-.3 0-.11-.045.02-.14.3-.52 1.527-2.55 7.486-7.193 2.722-2.445-2.508-1.313-5.016 3.136-5.774-2.545.434-5.407-.282-6.192-3.089C1.385 10.518 1 5.546 1 4.875c0-3.365 2.949-2.307 4.769-.941z' fill-rule='evenodd'/%3e%3c/svg%3e",
    'icon-checkbox-active': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cdefs%3e%3cpath d='M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm-9 14-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z' id='a'/%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd'%3e%3cmask id='b' fill='white'%3e%3cuse xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='%23a'/%3e%3c/mask%3e%3cg mask='url(%23b)' fill='black'%3e%3cpath d='M-13-13h50v50h-50z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e",
    'icon-checkbox-inactive': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cdefs%3e%3cpath d='M19 5v14H5V5h14zm0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z' id='a'/%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd'%3e%3cmask id='b' fill='white'%3e%3cuse xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='%23a'/%3e%3c/mask%3e%3cg mask='url(%23b)' fill='black'%3e%3cpath d='M-13-13h50v50h-50z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e",
    'icon-chevron-white': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3e%3cdefs%3e%3cpath id='a' d='m9.705 6.295-1.41 1.41 4.58 4.59-4.58 4.59 1.41 1.41 6-6z'/%3e%3c/defs%3e%3cg transform='rotate(90 12 12.295)' fill='none' fill-rule='evenodd'%3e%3cmask id='b' fill='white'%3e%3cuse xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='%23a'/%3e%3c/mask%3e%3cg mask='url(%23b)' fill='white'%3e%3cpath d='M-13.295-12.705h50v50h-50z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e",
    'icon-chevron': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3e%3cdefs%3e%3cpath id='a' d='m9.705 6.295-1.41 1.41 4.58 4.59-4.58 4.59 1.41 1.41 6-6z'/%3e%3c/defs%3e%3cg transform='rotate(90 12 12.295)' fill='none' fill-rule='evenodd'%3e%3cmask id='b' fill='white'%3e%3cuse xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='%23a'/%3e%3c/mask%3e%3cg mask='url(%23b)' fill='black'%3e%3cpath d='M-13.295-12.705h50v50h-50z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e",
    'icon-extern': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cdefs%3e%3cpath d='M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z' id='a'/%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd'%3e%3cmask id='b' fill='white'%3e%3cuse xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='%23a'/%3e%3c/mask%3e%3cg mask='url(%23b)' fill='black'%3e%3cpath d='M-13-13h50v50h-50z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e",
    'icon-instagram': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath d='M12.3.999h.283l.967.002c1.627.005 2.056.022 2.986.064 1.171.053 1.97.24 2.67.511a5.392 5.392 0 0 1 1.949 1.27 5.393 5.393 0 0 1 1.269 1.948c.272.7.458 1.499.511 2.67.046 1.011.062 1.43.065 3.44v2.191c-.003 2.01-.019 2.428-.065 3.44-.053 1.17-.24 1.97-.511 2.67a5.393 5.393 0 0 1-1.269 1.949 5.393 5.393 0 0 1-1.948 1.269c-.7.271-1.5.457-2.67.51-1.012.047-1.43.063-3.44.066h-2.192c-2.01-.003-2.428-.019-3.44-.065-1.17-.054-1.97-.24-2.67-.511a5.393 5.393 0 0 1-1.949-1.27 5.392 5.392 0 0 1-1.268-1.948c-.272-.7-.458-1.5-.512-2.67a55.538 55.538 0 0 1-.064-2.987L1 12.581v-1.164l.002-.967c.003-.99.01-1.537.025-2.023l.007-.205.008-.205.024-.553c.054-1.171.24-1.97.512-2.67a5.392 5.392 0 0 1 1.268-1.949 5.392 5.392 0 0 1 1.949-1.269c.7-.272 1.5-.458 2.67-.511.202-.01.381-.017.553-.024l.205-.008.206-.007a72.542 72.542 0 0 1 2.023-.025l.967-.002H12zm-.005 1.982h-1.371c-1.972.004-2.369.018-3.368.064-1.073.049-1.656.228-2.043.379-.514.2-.88.438-1.265.823a3.408 3.408 0 0 0-.823 1.265c-.15.387-.33.97-.379 2.042-.045 1-.06 1.397-.063 3.369v2.153c.003 1.972.018 2.369.063 3.369.05 1.072.228 1.655.38 2.042.199.514.437.88.822 1.265a3.41 3.41 0 0 0 1.265.823c.387.15.97.33 2.043.379 1 .045 1.396.06 3.368.063h2.153c1.973-.003 2.37-.018 3.369-.063 1.072-.05 1.655-.228 2.043-.379.513-.2.88-.438 1.264-.823.385-.385.624-.751.823-1.265.15-.387.33-.97.38-2.042.045-1 .06-1.397.062-3.369v-2.153c-.003-1.972-.017-2.369-.063-3.369-.049-1.072-.228-1.655-.379-2.042a3.41 3.41 0 0 0-.823-1.265 3.408 3.408 0 0 0-1.264-.823c-.388-.15-.97-.33-2.043-.379-1-.046-1.397-.06-3.369-.063h-.504L12 2.98zm-.294 3.37a5.649 5.649 0 1 1 0 11.297A5.649 5.649 0 0 1 12 6.35zm0 1.982a3.667 3.667 0 1 0 0 7.333 3.667 3.667 0 0 0 0-7.333zm5.872-3.526a1.32 1.32 0 1 1 0 2.64 1.32 1.32 0 0 1 0-2.64z' fill-rule='evenodd'/%3e%3c/svg%3e",
    'icon-linkedin': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath d='M20.45 20.45h-3.557v-5.569c0-1.328-.024-3.037-1.85-3.037-1.852 0-2.135 1.447-2.135 2.94v5.666H9.352V8.998h3.414v1.565h.048a3.741 3.741 0 0 1 3.368-1.85c3.604 0 4.269 2.37 4.269 5.455l-.002 6.282zM5.34 7.432a2.064 2.064 0 1 1-.001-4.127 2.064 2.064 0 0 1 0 4.127m1.78 13.018h-3.56V8.998h3.56V20.45zM22.222.003H1.771A1.751 1.751 0 0 0 0 1.733v20.536A1.753 1.753 0 0 0 1.771 24h20.451A1.756 1.756 0 0 0 24 22.269V1.73A1.755 1.755 0 0 0 22.222 0' fill-rule='evenodd'/%3e%3c/svg%3e",
    'icon-mastodon': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath d='M12.043 0c3.29.026 6.455.358 8.3 1.148 0 0 3.656 1.526 3.656 6.732v.4c-.005 1.05-.062 3.96-.51 6.109-.353 1.693-3.16 3.545-6.384 3.904-1.68.187-3.336.36-5.1.284-2.887-.123-5.165-.643-5.165-.643 0 .262.018.512.052.745.376 2.657 2.825 2.817 5.145 2.89 2.342.076 4.427-.538 4.427-.538l.096 1.975s-1.638.82-4.556.971c-1.609.083-3.607-.037-5.933-.612C1.024 22.12.156 17.101.023 12.01c-.04-1.512-.015-2.937-.015-4.13 0-5.206 3.657-6.732 3.657-6.732C5.509.358 8.673.026 11.962 0h.08zm3.717 4.07c-1.37 0-2.408.491-3.094 1.474L12 6.585l-.667-1.042C10.647 4.56 9.61 4.069 8.24 4.069c-1.184 0-2.138.388-2.866 1.146-.707.757-1.059 1.78-1.059 3.07v6.303h2.678V8.47c0-1.29.582-1.945 1.746-1.945 1.286 0 1.931.777 1.931 2.312v3.35h2.662v-3.35c0-1.535.645-2.312 1.932-2.312 1.163 0 1.745.655 1.745 1.945v6.118h2.678V8.284c0-1.288-.352-2.312-1.058-3.07-.729-.757-1.683-1.145-2.867-1.145z' fill-rule='evenodd'/%3e%3c/svg%3e",
    'icon-twitter': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath d='m.059 0 9.266 13.238L0 24.001h2.099l8.163-9.423 6.596 9.423H24l-9.787-13.982L22.892 0h-2.099l-7.518 8.678L7.2.001H.06zm3.086 1.652h3.28L20.914 22.35h-3.28L3.144 1.652z' fill-rule='evenodd'/%3e%3c/svg%3e",
    'icon-youtube': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath d='M9.522 15.635v-6.79l6.484 3.407-6.484 3.383zM23.76 7.667s-.235-1.665-.954-2.398c-.913-.963-1.936-.968-2.405-1.024C17.043 4 12.005 4 12.005 4h-.01s-5.038 0-8.396.245c-.47.056-1.492.06-2.406 1.024C.474 6.002.24 7.667.24 7.667S0 9.624 0 11.58v1.834c0 1.956.24 3.912.24 3.912s.234 1.665.953 2.399c.914.963 2.113.933 2.647 1.033C5.76 20.943 12 21 12 21s5.043-.007 8.401-.252c.47-.057 1.492-.061 2.405-1.024.72-.734.954-2.399.954-2.399s.24-1.956.24-3.912V11.58c0-1.955-.24-3.912-.24-3.912z' fill-rule='evenodd'/%3e%3c/svg%3e"
);

$sizes: (
    'ic-chevron-left': (
        'width': 24px,
        'height': 24px
    ),
    'ic-launch': (
        'width': 24px,
        'height': 24px
    ),
    'ic-search-white': (
        'width': 24px,
        'height': 24px
    ),
    'ic-search': (
        'width': 24px,
        'height': 24px
    ),
    'icon-arrow-black': (
        'width': 24px,
        'height': 24px
    ),
    'icon-arrow-left-white': (
        'width': 24px,
        'height': 24px
    ),
    'icon-arrow': (
        'width': 24px,
        'height': 24px
    ),
    'icon-bluesky': (
        'width': 24px,
        'height': 24px
    ),
    'icon-checkbox-active': (
        'width': 24px,
        'height': 24px
    ),
    'icon-checkbox-inactive': (
        'width': 24px,
        'height': 24px
    ),
    'icon-chevron-white': (
        'width': 25px,
        'height': 25px
    ),
    'icon-chevron': (
        'width': 25px,
        'height': 25px
    ),
    'icon-extern': (
        'width': 24px,
        'height': 24px
    ),
    'icon-instagram': (
        'width': 24px,
        'height': 24px
    ),
    'icon-linkedin': (
        'width': 24px,
        'height': 24px
    ),
    'icon-mastodon': (
        'width': 24px,
        'height': 24px
    ),
    'icon-twitter': (
        'width': 24px,
        'height': 24px
    ),
    'icon-youtube': (
        'width': 24px,
        'height': 24px
    )
);

$color: (
    'icon-arrow-black': (
        'color': '#262626'
    )
);

// https://github.com/waldemarfm/sass-svg-uri/blob/v1.0.0/_svg-uri.scss
@function sprite-str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if type-of($replace) == 'null' {
        $replace: '';
    }

    @if ( $index ) {
        @return str-slice($string, 1, $index - 1) + $replace + sprite-str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@function sprite-svg-uri($value) {
    $output: $value;

    $output: sprite-str-replace($output, '"', "'");
    $output: sprite-str-replace($output, '<', '%3C');
    $output: sprite-str-replace($output, '>', '%3E');
    $output: sprite-str-replace($output, '&', '%26');
    $output: sprite-str-replace($output, '#', '%23');

    @return $output;
}

@mixin sprite($name, $user-variables: (), $include-size: false) {
    $sprite: map-get($sprites, $name);

    // Inject variables
    $default-variables: map-get($color, $name);
    @if type-of($default-variables) == 'map' {
        @each $key, $value in map-merge($default-variables, $user-variables) {
            @if ( not map-has-key($default-variables, $key) ) {
                @warn 'Sprite \'#{$name}\' does not support variable named \'#{$key}\'';
            }

            $sprite: sprite-str-replace($sprite, '___#{$key}___', sprite-svg-uri(quote(#{$value})));
        }
    } @else if type-of($user-variables) == 'map' {
        @warn 'Sprite \'#{$name}\' does not contain any variables';
    }

    background: url($sprite) center no-repeat;

    @if $include-size {
        $size: map-get($sizes, $name);
        @if $include-size == true {
            background-size: map-get($size, width) map-get($size, height);
        } @else if $include-size == 'box' {
            width: map-get($size, width);
            height: map-get($size, height);
        }
    }
}
