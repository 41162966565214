
.m-filter__checkbox {
    cursor: pointer;
    display: inline-block;
  }
  
  .m-filter__checkboxlabel {
    @extend %body;
  }
  
  .m-filter__checkboxinput::after {
    @include sprite('icon-checkbox-inactive');
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform:  translateX(-50%) translateY(-50%);
    width: 24px;
    height: 24px;
    display: inline-block;
  }
  .m-filter__checkboxinput:checked::after {
    @include sprite('icon-checkbox-active');
  }
  .m-filter__checkboxcontainer1 {
    position: relative;
    height: 50px;
    padding-top:12px;
    @include make-col-ready;
    @include make-col(12);

    @include media-breakpoint-up(lg) {
      @include make-col(2);
    }
  }
  .m-filter__checkboxcontainer1 .m-filter__checkboxinput::after{
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
  }
  .m-filter__checkboxcontainer1 .m-filter__checkboxlabel {
    @extend %body;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

  }
  .m-filter__checkboxcontainer3 .m-filter__checkboxinput::after{
    right: -10px;
  }
  .m-filter__checkboxcontainer3 .m-filter__checkboxlabel {
    @extend %body;
    position: absolute;
    overflow-x: visible;
    left: 50%;
    width: 70%;
    transform: translateX(-45%);

  }
  .m-filter__checkboxcontainer3 {
    position: relative;
    height: 50px;
    padding-top:6px;
    @include make-col-ready;
    @include make-col(12);
    

    @include media-breakpoint-up(lg) {
      @include make-col(3);
    }
  }