.m-teaserwide {
  @extend %ctype-base;
  padding: 48px 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 88px);
    background: $greige-30; /* Inherit the background color */
    clip-path: polygon(0 0, 100% 60px, 100% 100%, 0 100%);
    @include media-breakpoint-up(lg) {
      clip-path: polygon(0 0, 100% 120px, 100% 100%, 0 100%);
      height: calc(100% + 120px);
    }
    z-index: -1;
  }

  @include media-breakpoint-up(lg) {
    padding: 64px 0;
  }
}

.m-teaserwide__content {
  max-width: 848px;
}

.m-teaserwide__title {
  margin-bottom: 24px;
}

.m-teaserwide__text {
  margin-bottom: 24px;
}

.m-teaserwide__link {
  @extend %arrow-link;
}
