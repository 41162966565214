.m-text__content {
  @extend %ctype-base;
  @include make-col(12);

  @include media-breakpoint-up(lg) {
    @include make-col-offset(2);
    @include make-col(8);
  }
}

.m-news-detail__content .m-text__content {
  @include make-col(12);
  @include make-col-offset(0);
  padding: 0;
  @include media-breakpoint-up(lg) {
    @include make-col-offset(0);
    padding: 0;
    @include make-col(12);
  }
}

:is(.col-md-10,.col-10,.col-lg-10) .m-text__content {
  @include make-col(12);
  @include make-col-offset(0);
  padding: 0;
  @include media-breakpoint-up(lg) {
    @include make-col-offset(0);
    padding: 0;
    @include make-col(12);
  }
}

.m-text__content--aligned-left {
  @extend %ctype-base;
  @include make-col(12);

  @include media-breakpoint-up(lg) {
    @include make-col(8);
  }
}

.m-text {
  @extend %text_format;
}
