.m-breadcrumb {
  display: none;
  margin-bottom: $spacing-l;
  @include media-breakpoint-up(lg) {
    display: block;
  }
}
.m-breadcrumb__link {
  @extend %label;
  &.current {
    color: $dusk-blue;
    text-decoration: underline;
    text-underline-offset: 5px;
  }
}

.m-breadcrumb__items {
  display: flex;
  flex-flow: row wrap;
}

.m-breadcrumb__item {
  &:not(:last-child) {
    &::after {
      @extend %label;
      content: '|\00a0';
    }
  }
}
