@charset "UTF-8";

@import 'base/vars';

@import 'base/reset';
@import '~normalize.css/normalize.css';

@import 'base/fonts';

@import 'base/mixins';
@import 'base/animations';

@import 'resources/bootstrap';
@import 'resources/sprites';
@import 'base/textstyles';
@import 'base/inputs';

/*layouts*/
@import 'layouts/default';
@import 'layouts/fancy-header';

/* Components */
@import 'modules/ctypes';
@import 'modules/navs';
@import 'modules/searchbar';
@import 'modules/zoterosearchbar';
@import 'modules/zoteropagination';
@import 'modules/dropdown';
@import 'modules/filtercheckbox';
@import 'modules/pagination';
@import 'modules/dropdownborderless';
@import 'modules/filteredarticle';
@import 'modules/filteredarticles';
@import 'modules/menus';
@import 'modules/person-list';
@import 'modules/iframe';
@import 'modules/matomo';


/* Pages */
@import 'pages/news-detail';
@import 'pages/event-list';

[class^='wzb'] svg {
  //todo remove. just for better overview for now (old svg icons were dislayed huge)
  display: block;
  max-width: 24px !important;
}
