.m-filteredarticles{
  margin-bottom: 36px;
  @include media-breakpoint-up(lg) {
    margin-bottom: 48px;
  }

  &>.container>.row{
    .m-zoterosearchbar{
      order: 1;
    }
    .m-filteredarticles__filters{
      order: 2;
    }
    .m-filteredarticles__reset{
      order: 3;
    }
    .m-filteredarticles__itemCount{
      order:4;
    }
    .m-dropdownborderless{
      order:5;
    }
    @include media-breakpoint-up(lg) {
      .m-filteredarticles__reset{
        order: 2;
      }
      .m-filteredarticles__filters{
        order: 3;
      }
    }
  }
}

.m-filteredarticles__reset {
  @include make-col(12);
  margin-bottom: 60px;
  margin-top: 20px;

  @include media-breakpoint-up(lg) {
    @include make-col(4);
    margin-top: 0px;
  }
}

.m-filteredarticles__resetwrapper {
  width: 100%;
  position: relative;
  @include media-breakpoint-up(lg) {
    text-align: right;
  }
}

.m-filteredarticles__resetbutton {
  @extend %body;
  padding: 0;
  background: none;
	color: inherit;
	border: none;
	cursor: pointer;
  text-decoration: underline;
  text-decoration-color: $orange;
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
}
.m-filteredarticles__filters{
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.m-filteredarticles__itemCount {
  @include make-col(12);
  margin-bottom: 24px;

  @include media-breakpoint-up(lg) {
    @include make-col(8);
    margin-bottom: 0px;

  }
}