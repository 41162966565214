.m-dropdown {
  position: relative;
  @include make-col-ready;
  @include make-col(12);
  margin-bottom: 24px;

  @include media-breakpoint-up(lg) {
    @include make-col(4);
  }
}

.m-dropdown__head {
  padding: 12px;
  border: 1px solid black;
  display: flex;
  justify-content: space-between;
}


.m-dropdown__headtitle {
  pointer-events: none;

}

.m-dropdown__headarrow {
  @include sprite('icon-chevron');
  height: 25px;
  width: 25px;
  display: inline-block;
  pointer-events: none;

  .wzb-js-open & {
    transform: rotate(-180deg);
  }
}

.m-dropdown__items {
  display: none;
  width: 100%;
  padding: inherit;
  position: absolute;
  left: 0;

  .wzb-js-open & {
    display: block;
    position: absolute;
    z-index: 1;
  }
}



.m-dropdown__item {
  position: relative;
  padding: 12px;
  background-color: $white;
  border-left: 1px solid black;
  border-right: 1px solid black;

  &:last-child {
    border-bottom: 1px solid black;
  }
}

.m-dropdownitem__filterinterval {
  background-color: $white;
  border: 1px solid black;
  border-top: none;
  padding-top: 12px;
  padding-bottom: 12px;
}

.m-dropdownitem__filterinterval--label {
  display: flex;
  
  &:first-child {
    margin-bottom: 12px;
  }
}

.m-dropdownitem__filterinterval--text {
  @extend %body;
  margin-left: 12px;
  width: 45px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.m-dropdownitem__filterinterval--input {
  @extend %body;
  width: min-content;
  padding: 5px 35px;
  margin: 0px;
  background-color: $dark-gold-30;
  border:none;

  &::-webkit-inner-spin-button, &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
    margin: 0px;
    padding: 0px;
  }
}



.m-dropdown__checkbox {
  cursor: pointer;
  display: inline-block;
}

.m-dropdown__checkboxlabel {
  @extend %body;
}

.m-dropdown__checkboxinput::after {
  @include sprite('icon-checkbox-inactive');
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 24px;
  height: 24px;
  display: inline-block;
}

.m-dropdown__checkboxinput:checked::after {
  @include sprite('icon-checkbox-active');
}
