.m-searchform {
  background-color: $greige-30;
  padding: 30px 0;
  margin-bottom: $spacing-xl;
}

.m-search__results {
  @extend %ctype-base;
}
.m-search__result {
  padding: $spacing-l 0;
  border-bottom: 1px solid $black;
  &:first-of-type {
    border-top: 1px solid $black;
  }

  .results-highlight {
    display: inline-block;
    background-color: $dusk-blue;
    color: $white;
  }
}
.m-search__result-title {
  margin-bottom: $spacing-l;
}

.m-search__result-link {
  display: block;
  max-width: 848px;
}

.m-searchform__wrapper {
  position: relative;
  max-width: 750px;
}

.m-search__searched-for {
  margin-bottom: 36px;
  @include media-breakpoint-up(lg) {
    margin-bottom: $spacing-xl;
  }
}

.m-searchform__input {
  display: block;
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 0;
  outline: none;
}

.m-search__button {
  position: absolute;
  display: block;
  top: 12px;
  right: 12px;
  border: none;
  color: $black;
  cursor: pointer;
  background: $black;
  mask-image: url(../images/icons/ic-search.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  height: 24px;
  width: 24px;
  font-size: 0;
}

.m-search__result-num {
  @extend %small;
  margin-bottom: 36px;
  @include media-breakpoint-up(lg) {
    margin-bottom: $spacing-xl;
  }
}

.m-search__result-list {
  margin-bottom: $spacing-xl;
}

.m-search__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.m-search__pagination--next {
  margin-left: 16px;
  a {
    display: block;
    height: 24px;
    width: 24px;
    font-size: 0;
    mask-image: url(../images/icons/ic-chevron-left.svg);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    transform: rotate(180deg);
    background-color: currentColor;
  }
}

.m-search__pagination--prev {
  margin-right: 16px;
  a {
    display: block;
    height: 24px;
    width: 24px;
    font-size: 0;
    mask-image: url(../images/icons/ic-chevron-left.svg);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    background-color: currentColor;
  }
}

.m-search__pagination--num {
  @extend %label;
  display: block;
  padding: 4px 6px;
}

.m-search__pagination--element.active {
  background-color: $dusk-blue;
  a {
    color: $white;
  }
}
