.m-fancy-header{
  position: relative;
  background-color: $greige-30;
  padding: 48px 0px;
  margin-bottom: 48px;
}

.m-fancy-header__leftdecoration{
  display:none;

  @include media-breakpoint-up(lg) {
    display:block;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.m-fancy-header__rightdecoration{
  display:none;

  @include media-breakpoint-up(lg) {
    display:block;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.m-fancy-header__content {
  @include make-col(12);

  @include media-breakpoint-up(lg) {
    &:not(.is-expanded) {
      @include make-col-offset(2);
      @include make-col(8);
    }

    &.is-expanded {
      @include make-col(8);
    }
  }
}

.m-fancy-header__head {
  @extend %h1;
  padding-bottom: 24px;
}

.m-fancy-header__description {
  @extend %subline;
}