.m-zoteropagination {
  display: flex;
  width: fit-content;
  margin: 48px auto;

  & button {
    height: 24px;
    width: 24px;
    background-color: transparent;
  	border: none;
  	padding: 0;
  	font: inherit;
  	cursor: pointer;
  	outline: inherit;
  }

  &__page,
  &__more {
    font-weight: normal;
    margin: 0 4px;
    padding: 4px 5.5px;
  }
  &__current{
    background-color: $dusk-blue !important;
    color: $white;
  }
  &__previous ,
  &__next  {
    @include sprite('icon-chevron');
    content: '';
    display: block;
    height: 100%;
    width: 24px;
  }
  &__previous  {
    transform: rotate(90deg);
    margin-right: 24px;
  }
  &__next  {
    transform: rotate(270deg);
    margin-left: 24px;
  }
  @include media-breakpoint-up(lg) {
    margin: 60px auto;
  }
}
