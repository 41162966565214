.m-publication-teasers {
  @extend %ctype-base;
  &:last-child {
    margin-bottom: 0;
  }
}

.m-publication-teasers__item {
  padding: $spacing-xl 0;

  &:nth-child(odd) {
    background: $greige-10;
  }

  &--headline {
    margin-bottom: $spacing-l;
    max-width: 848px;
  }

  &--copy {
    margin-bottom: $spacing-xl;
    max-width: 848px;
  }

  &--fileWrapper {
    margin-bottom: $spacing-l;
    display: flex;
    flex-flow: row wrap;
    column-gap: 30px;
  }

  &--fileData {
    flex: 1 1 100%;
    @include media-breakpoint-up(lg) {
      flex: 1;
    }
  }

  &--fileTitle {
    margin-bottom: $spacing-l;
  }

  &--fileDescription,
  &--fileAuthor {
    margin-bottom: $spacing-l;
  }

  &--imageWrapper {
    width: 50%;
    margin-bottom: $spacing-l;
    @include media-breakpoint-up(lg) {
      max-width: 165px;
      margin-bottom: 0;
    }
  }

  &--image {
    width: 100%;
    height: auto;
    &[src*='.png'] {
      background-color: white;
    }
  }

  &--link,
  &--fileLink {
    @extend %arrow-link;
  }

  &--fileAuthor {
    font-style: italic;
  }
}
