%checkbox {
  cursor: pointer;
  & + label {
    display: flex;
    
    cursor: pointer;
  }

  & + label::before {
    content: '';
    display: inline-block;
    height: 24px;
    width: 24px;
    flex: 0 0 24px;
    display: block;
    margin-right: 10px;
    @include sprite('icon-checkbox-inactive');
  }

  &:checked + label::before {
    @include sprite('icon-checkbox-active');
  }
}
