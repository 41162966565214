%ctype-base {
  margin-bottom: 80px;
  @include media-breakpoint-up(lg) {
    margin-bottom: 120px;
  }
}
@import 'ctypes/m-anchor-navigation';
@import 'ctypes/m-partner-element';
@import 'ctypes/m-tripleteaser';
@import 'ctypes/m-teaserwideimage';
@import 'ctypes/m-teaserwide';
@import 'ctypes/m-frontpage-hero';
@import 'ctypes/m-contact-people';
@import 'ctypes/m-people-gallery';
@import 'ctypes/m-person-detail';
@import 'ctypes/m-text';
@import 'ctypes/m-teaser-big';
@import 'ctypes/m-cardsthreecolumns';
@import 'ctypes/m-detailedmenu';
@import 'ctypes/m-news-all';
@import 'ctypes/m-publication-teasers';
@import 'ctypes/m-cards-wide';
@import 'ctypes/m-image-text-media';
@import 'ctypes/m-image';
@import 'ctypes/m-sharepics';
@import 'ctypes/m-search';
@import 'ctypes/m-newsletterform';
