@keyframes fadeInFromRight {
  0% {
    opacity: 0;
    translate: 20px 0;
  }
  to {
    opacity: 1;
    translate: 0 0;
  }
}

@keyframes fadeInFromBottom {
  0% {
    opacity: 0;
    translate: 0 20px;
  }
  to {
    opacity: 1;
    translate: 0 0;
  }
}