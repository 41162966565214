.m-teaserwideimage {
  padding: 48px 0;
  position: relative;

  @include media-breakpoint-up(lg) {
    padding-top: 67px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 60px);
    background: $greige-10; /* Inherit the background color */
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 60px));
    @include media-breakpoint-up(lg) {
      height: 100%;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 120px));
    }
    z-index: -1;
  }
}

.m-teaserwideimage__container {
  @extend .container;
}

.m-teaserwideimage__row {
  @extend .row;
}

.m-teaserwideimage__text {
  @include make-col-ready();
  @include make-col(12);

  margin-bottom: $spacing-xl;

  @include media-breakpoint-up(lg) {
    @include make-col(7);
  }
}

.m-teaserwideimage__image {
  @include make-col-ready();
  @include make-col(12);

  margin-bottom: -30px;
  @include media-breakpoint-up(lg) {
    margin-bottom: -80px;
    @include make-col(5);
  }
}

.m-teaserwideimage__title {
  margin-bottom: $spacing-l;
}

.m-teaserwideimage__link {
  @extend %arrow-link;
}
