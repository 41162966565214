@font-face {
  font-family: 'Bitter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('../fonts/Bitter/Bitter-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Bitter';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url('../fonts/Bitter/Bitter-Italic-VariableFont_wght.ttf')
    format('truetype');
}

@font-face {
  font-family: 'RobotoCondensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/Roboto_Condensed/RobotoCondensed-Light.ttf')
    format('truetype');
}

@font-face {
  font-family: 'RobotoCondensed';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/Roboto_Condensed/RobotoCondensed-LightItalic.ttf')
    format('truetype');
}

@font-face {
  font-family: 'RobotoCondensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf')
    format('truetype');
}

@font-face {
  font-family: 'RobotoCondensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf')
    format('truetype');
}

@font-face {
  font-family: 'RobotoCondensed';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Roboto_Condensed/RobotoCondensed-Italic.ttf')
    format('truetype');
}

@font-face {
  font-family: 'RobotoCondensed';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Roboto_Condensed/RobotoCondensed-BoldItalic.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('../fonts/Roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url('../fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Roboto/Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/Roboto/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
}
