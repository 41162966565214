.m-dropdownborderless {
  @include make-col(12);
  margin-bottom:24px; 

  @include media-breakpoint-up(lg){
    @include make-col(4);
  }
}

.m-dropdownborderless__wrapper {
  position: relative;
  background-color: white;
  justify-content: right;
  @include media-breakpoint-up(lg){
    justify-content: left;
  }
}

.m-dropdownborderless__head {
  display: flex;
  justify-content: left;
  @include media-breakpoint-up(lg){
    justify-content: right;
  }
}

.m-dropdownborderless__headarrow {
  @include sprite('icon-chevron');
  height: 25px;
  width: 25px;
  display: inline-block;

  .wzb-js-open & {
    transform: rotate(-180deg);
  }
}

.m-dropdownborderless__items {
  z-index: 1;
  display: none;
  width: max-content;
  margin-top: 12px;
  padding: 12px 14px;
  float: left;
  @include media-breakpoint-up(lg){
    float: right;
  }

  .wzb-js-open & {
    display: block;
    position: absolute;
    z-index: 1;
    background-color: $white;
    border: 1px solid $black;

    @include media-breakpoint-up(lg){
      right:0;
    }
  }

  &>.m-dropdownborderless__item {
    margin: 8px 0px;
  }
  &>.m-dropdownborderless__item:first-child {
    margin-top: 0px;
  }
  &>.m-dropdownborderless__item:last-child {
    margin-bottom: 0px;
  }
}

.m-dropdownborderless__itembutton {
  @extend %body;
  background: none;
	border: none;
	padding: 0;
	cursor: pointer;
}