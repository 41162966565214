.m-person-list {
  @extend %ctype-base;

  .wzb-filters {
    display: flex;
    flex-flow: row wrap;
    column-gap: $grid-gutter-width;
    row-gap: $spacing-l;
    margin-bottom: 120px;
    @include media-breakpoint-up(lg) {
      margin-bottom: $spacing-xl;
    }
  }

  .wzb-filter {
    flex: 1 1 360px;
    position: relative;
    @include media-breakpoint-up(sm) {
      flex: 0 1 263px;
    }

    &.wzb-js-open {
      .wzb-filter__items {
        display: block;
      }

      .wzb-filter__head::after {
        transform: rotate(180deg);
      }
    }
  }

  .wzb-filter__head {
    border: 1px solid $black;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    white-space: nowrap;
    justify-content: space-between;
    cursor: pointer;

    &:after {
      content: '';
      @include sprite('icon-chevron');
      display: block;
      height: 24px;
      width: 24px;
      margin-left: 12px;
    }
  }

  .wzb-filter__items {
    display: none;
    border: 1px solid $black;
    position: absolute;
    background: $white;
    border-top: none;
    width: 100%;
    z-index: 10;
  }

  .wzb-filter__item {
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    .wzb-checkbox__checkbox {
      display: none;
    }

    .wzb-checkbox__label {
      padding: $spacing-m;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      &::after {
        content: '';
        height: 24px;
        width: 24px;
        display: block;
        @include sprite('icon-checkbox-inactive');
      }
    }
    :checked + .wzb-checkbox__label::after {
      @include sprite('icon-checkbox-active');
    }

    &:hover {
      background: $greige-30;
    }
  }

  .wzb-lettersFilter {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
    margin-bottom: $spacing-xl;
  }

  .wzb-lettersFilter__letter {
    @extend %label;
    cursor: pointer;
  }

  .wzb-radioButton__input:checked + .wzb-radioButton__label {
    color: $dusk-blue;
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  .wzb-filteredArticles__articlesList {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    column-gap: $grid-gutter-width;
    row-gap: $spacing-xl;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      row-gap: $spacing-xl;
    }
  }

  .wzb-filteredArticle__head {
    margin-bottom: 16px;
  }

  .wzb-filteredArticles__button {
    border: none;
    background: none;
    cursor: pointer;
    margin-top: $spacing-xl;

    span {
      @extend %arrow-link;
    }
  }
}

.m-person-list__item {
  display: none;
  animation: fadeInFromBottom 0.5s ease-in-out;
  &.wzb-js-show {
    display: block;
  }
}

.m-person-list__itemImage {
  aspect-ratio: 360 / 276;
  margin-bottom: 16px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
