.m-sharepics {
  @extend %ctype-base;
}
.m-sharepics__list {
  display: grid;
  gap: 32px 9px;
  grid-template-columns: repeat(1, 1fr);
  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.m-sharepics__item {
  &:hover {
    .m-sharepics__linktext::after {
      @include sprite(
        'icon-arrow-black',
        (
          color: $orange,
        )
      );
    }
  }
}
.m-sharepics__link {
    img{
        transition: scale 0.3s ease;
    }
  &:hover {
    img {
      scale: 1.01;
    }
  }
}

.m-sharepics__linktext {
  display: flex;
  align-items: center;
  min-height: 32px;
  margin-top: 9px;
  &::after {
    @include sprite('icon-arrow-black');
    display: inline-block;
    content: '';
    height: 24px;
    width: 24px;
    margin-left: 10px;
  }
}
