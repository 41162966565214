%display {
  font-family: $roboto_condensed;
  font-weight: $regular;
  font-size: 28px;
  letter-spacing: -0.37px;
  line-height: normal;

  @include media-breakpoint-up(lg) {
    font-size: 35px;
    letter-spacing: -0.47px;
  }
}

%h1,
h1 {
  font-family: $roboto_condensed;
  font-weight: $light;
  line-height: normal;
  font-size: 30px;
  letter-spacing: -0.6px;

  @include media-breakpoint-up(lg) {
    font-size: 45px;
  }
}

%h2,
h2 {
  font-family: $roboto_condensed;
  font-weight: $light;
  line-height: normal;
  font-size: 28px;
  letter-spacing: -0.5px;

  @include media-breakpoint-up(lg) {
    font-size: 36px;
  }
}

%h3,
h3 {
  font-family: $roboto_condensed;
  font-weight: $light;
  font-size: 26px;
  line-height: normal;

  @include media-breakpoint-up(lg) {
    font-size: 30px;
  }
}

%h4,
h4 {
  font-family: $roboto_condensed;
  font-weight: $light;
  font-size: 24px;
  line-height: normal;
}

%h5,
h5 {
  font-family: $roboto_condensed;
  font-weight: $light;
  font-size: 21px;
  letter-spacing: -0.5px;
  line-height: normal;
}

%subline,
.subline {
  font-family: $roboto_condensed;
  font-weight: $regular;

  font-size: 20px;

  @include media-breakpoint-up(lg) {
    font-size: 21px;
    line-height: 28px;
  }
}

%external-link,
.external-link {
  &::after {
    @include sprite('icon-extern');
    content: '';
    position: relative;
    top: 5px;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-left: 10px;
  }
}

%body {
  font-family: $bitter;
  font-weight: $regular;
  font-size: 18px;

  @include media-breakpoint-up(lg) {
    font-size: 18px;
    line-height: 24px;
  }
}

%body-small {
  font-family: $bitter;
  font-weight: $regular;
  font-size: 16px;
}

%p {
  font-family: $bitter;
  font-weight: $regular;
  font-size: 18px;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    font-size: 18px;
    line-height: 24px;
  }
}

%menu {
  font-family: $roboto_condensed;
  font-weight: $regular;
  font-size: 18px;
  line-height: 24px;
}

%submenu {
  font-family: $roboto_condensed;
  font-weight: $regular;
  font-size: 16px;
}

%small {
  font-family: $bitter;
  font-weight: $regular;
  font-size: 16px;

  @include media-breakpoint-up(lg) {
    line-height: 21px;
  }
}

%button {
  font-family: $bitter;
  font-weight: $bold;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
}

%link,
a {
  font-family: $bitter;
  font-weight: $bold;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-color: $orange;
    color: $orange;
  }
}

p a {
  font-size: inherit;
}

%arrow-link,
.arrow-link a,
a.arrow-link {
  position: relative;
  display: inline-block;
  padding-right: 34px;

  &:hover {
    color: inherit;
    text-decoration: none;

    &::before {
      transform: scaleX(1);
    }
  }

  &::before {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-color: $orange;
    position: absolute;
    bottom: -5px;
    left: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
  }

  &::after {
    content: '';
    @include sprite('icon-arrow');
    height: 24px;
    width: 24px;
    display: block;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
}

%ul-li {
  position: relative;
  padding-left: 35px;

  &::before {
    content: '•';
    position: absolute;
    left: 10px;
    top: 0;
    font-weight: $bold;
  }
}

%ol-li {
  position: relative;
  padding-left: 35px;
  list-style: none;
  counter-increment: item;

  &::before {
    content: counter(item) '.';
    position: absolute;
    left: 10px;
    top: 0;
  }
}

%label {
  font-family: $bitter;
  font-weight: $regular;
  font-size: 14px;
}

body {
  @extend %body;
}

p {
  @extend %p;
}

a {
  color: inherit;
}

strong,
b {
  font-weight: $bold;
}

u {
  text-decoration: underline;
}

i {
  font-style: italic;
}

%text_format {

  strong,
  b {
    font-weight: $bold;
  }

  i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  h2,
  h3,
  h4 {
    margin-bottom: $spacing-l;
  }

  ul {
    &:not(:last-child) {
      margin-bottom: $spacing-l;
    }

    li {
      @extend %ul-li;
    }
  }

  ol {
    li {
      @extend %ol-li;
    }

    &:not(:last-child) {
      margin-bottom: $spacing-l;
    }
  }
}