.m-listwithimage__listitem {
    @include make-row;

    +.m-listwithimage__listitem {
        margin-top: 50px;
    }
}

.m-listwithimage__listitemimage {
    @include make-col-ready;
    @include make-col(12);
    margin-bottom: 24px;

    @include media-breakpoint-up(md) {
        @include make-col(5);
        margin-bottom: unset;
    }

}

.m-listwithimage__listitemcontent {
    @include make-col-ready;
    @include make-col(12);

    @include media-breakpoint-up(md) {
        @include make-col(7);
    }
}

.m-listwithimage__listitemheader {
    @extend %h3;
    margin-bottom: 30px;
}

.m-listwithimage__header{
    margin-bottom: $spacing-xl;
}

.m-listwithimage__listitemabstract {
    @extend %body;
    margin-bottom: 30px;
}

.m-listwithimage__listitemlink {
    @extend %link;
    display: flex;
    margin-bottom: 30px;

    &::after {
        content: '';
        @include sprite('icon-arrow');
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-left: 10px;

    }
}