.m-zoterosearchbar {
  display: flex;
  width: 100%;
  margin-bottom: 48px;

  @include make-col(12);

  @include media-breakpoint-up(lg) {
    @include make-col(4);
  }
}

.m-zoterosearchbar__wrapper {
  position: relative;
  width: 100%;
}

.m-zoterosearchbar__input {
  @extend %body;
  width: 100%;
  background-color: transparent;
  color: black;
  padding-right: 32px;
  border: none;
  border-bottom: 1px solid black;
}

.m-zoterosearchbar__submit {
  padding: 0;
  position: absolute;
  display: inline-block;
  height: 20px;
  width: 20px;
  right: 0;
  top: 2;
  background-color: transparent;
  border: none;
}