.m-detailedmenu {
  @extend %ctype-base;
}

.m-detailedmenu__head {
  margin-bottom: $spacing-xl;
  @include media-breakpoint-up(lg) {
    margin-bottom: 60px;
  }
}

.m-detailedmenu__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  row-gap: $spacing-xl;
  column-gap: var(--bs-gutter-x, 30px);
}

.m-detailedmenu__main-link,
.m-detailedmenu__sub-link {
  display: flex;
  min-height: 70px;
  margin-bottom: 1px;
  align-items: center;
}

.m-detailedmenu__main-link {
  background: $greige-10;
  transition: all 0.3s ease-in-out;
  padding: 20px;
  &:hover {
    color: unset;
    background: $dark-gold-30;
    text-decoration: none;
  }
}

.m-detailedmenu__sub-link {
  @extend %body;
  background: $greige-30;
  transition: all 0.3s ease-in-out;
  padding: 11px 20px;
  &:hover {
    color: unset;
    background: $dark-gold-30;
    text-decoration: none;
  }
}
