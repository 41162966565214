.m-person-detail * {
  scroll-margin-top: 93px;

  @include media-breakpoint-up(lg) {
    scroll-margin-top: 182px;
  }
}
.m-person-detail__header {
  background-color: $greige-30;
  padding-top: 48px;
  padding-bottom: 128px;

  @include media-breakpoint-up(md) {
    padding-top: 93px;
    padding-bottom: 48px;
  }
}

.m-person-detail__header-content {
  @include make-col(12);

  @include media-breakpoint-up(md) {
    @include make-col-offset(2);
    @include make-col(4);
  }
}
.m-person-detail__header-content-head {
  @extend %h1;
  padding-bottom: 24px;
}
.m-person-detail__header-content-position {
  @extend %subline;
}

.m-person-detail__image {
  @include make-col(12);

  margin-top: -100px;
  margin-bottom: 48px;

  width: 338px;
  height: 246px;
  object-fit: cover;

  @include media-breakpoint-up(md) {
    @include make-col-offset(6);
    @include make-col(4);

    margin-top: -240px;

    width: 360px;
    height: 295px;
  }
}
.m-person-detail__anchorlist {
  @include media-breakpoint-up(lg) {
    position: sticky;
    top: 182px;
  }
}

.m-person-detail__descriptionheader {
  margin-bottom: 24px;
}
.m-person-detail__description {
  @extend %body;
  margin-bottom: 48px;
}

.m-person-detail__information {
}
.m-person-detail__informationheader {
  margin-bottom: 24px;
}

.m-person-detail__informationcontent {
  @extend %body;
  margin-bottom: 48px;
}

.m-person-detail__contact {
  margin-bottom: 40px;
  padding-block: 24px;
  @include media-breakpoint-up(lg) {
    margin-bottom: 60px;
  }
  background-color: $greige-10;
}

.m-person-detail__contacttitle {
  @extend %h4;
  padding: 0 24px 24px;
  margin-bottom: 1px;
}

.m-person-detail__contactdetail {
  @extend %small;
  padding: 0px 24px;
  margin-bottom: 1px;
  display: flex;
  flex-wrap: wrap;
}

.m-person-detail__contactdetaillabel {
  flex: 0 0 100%;
  margin-bottom: 12px;

  @include media-breakpoint-up(md) {
    flex: 0 0 15%;
  }
}

.m-person-detail__contactdetailvalue {
  flex: 0 0 100%;

  @include media-breakpoint-up(md) {
    flex: 0 0 85%;
    padding-left: 20px;
  }
}

.m-person-detail__publicationsheader {
  margin-bottom: 24px;
}
.m-person-detail #wzb-zotero .container {
  padding: 0;
}
.m-person-detail .m-listwithimage .container {
  padding: 0;
}

.m-person-detail {
  ul {
    &:not(:last-child) {
      margin-bottom: $spacing-l;
    }
    li {
      @extend %ul-li;
    }
  }

  ol {
    li {
      @extend %ol-li;
    }

    &:not(:last-child) {
      margin-bottom: $spacing-l;
    }
  }
}

.m-person-detail__sociallink {
}