.frame {
  @extend %ctype-base;
  @extend .container;
  display: flex;
  justify-content: center;
  iframe {
    width: 100%;
    max-width: 750px;
    aspect-ratio: 16 / 9;
  }
}
