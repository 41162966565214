.m-footer {
  background-color: #c9cfd6;
  padding-bottom: 30px;
}
.m-footer__menuContainer {
  @include make-container();
  padding-top: 66px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr 0.25fr 0.5fr 0.5fr;
  max-width: map-get($container-max-widths, xs);
  @include media-breakpoint-up(lg) {
    max-width: map-get($container-max-widths, lg);
  }
}
.m-footermainnavigation {
  display: flex;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: span 2;
}
.m-mainfooter__column {
  width: 33%;
  &:nth-child(3) {
    padding-left: 50px;
  }
}

.m-footersidenavigation {
  grid-column-start: 4;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: span 2;
}
.m-mainfooter__menulink {
  overflow: hidden;
  font-family: "RobotoCondensed";
  font-size: 21px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #242428;
}

.m-mainfooter__menuitemchild {
  padding-top: 18px;
}
.m-mainfooter__menuitem {
  margin-bottom: 20px;
}
.m-mainfooter__menuchildlink {
  font-family: "Bitter-Regular";
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #242428;
}
.m-sidefooter__menuitem {
  padding-bottom: 22px;
}
.m-sidefooter__menulink {
  overflow: hidden;
  font-family: "RobotoCondensed";
  font-size: 21px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #242428;
}
.m-footersocialsnavigation {
  align-self: center;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 4;
  grid-row-end: span 1;
}
.m-footermetanavigation {
  align-self: center;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 5;
  grid-row-end: span 1;
}
.m-metafooter__menuitem {
  display: inline-block;
  margin-top: 24px;
  margin-right: 50px;
}
.m-socialsfooter__menuitem {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-top: 24px;
  margin-right: 24px;
}
.m-socialsfooter__menuitem a {
  font-size: 0px;
  color: #c9cfd6;
  display: block;
  width: 24px;
  height: 24px;
}
.twitter {
  @include sprite("icon-twitter");
}
.x {
  @include sprite("icon-twitter");
}
.mastodon {
  @include sprite("icon-mastodon");
}
.youtube {
  @include sprite("icon-youtube");
}
.linkedin {
  @include sprite("icon-linkedin");
}
.bluesky {
  @include sprite("icon-bluesky");
}
.instagram {
  @include sprite("icon-instagram");
}

@include media-breakpoint-down(md) {
  .m-footer__menuContainer {
    width: 100%;
    padding-top: 41px;
    padding-left: 26px;
    // display: grid;
    display: block;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.5fr 0.5fr 0.5fr 0.25fr 0.25fr;
  }
  .m-footermainnavigation {
    // display: flex;
    // flex-wrap: wrap;
    display: block;
    grid-template-columns: 2fr 2fr;
    // grid-template-rows: 0.7fr 1fr;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    margin-bottom: 10px;
    // grid-row-end: span 1;
  }
  .m-mainfooter__column {
    width: 50%;
    &:nth-child(3) {
      padding-left: unset;
    }
    margin-bottom: 80px;
  }
  .m-mainfooter__menuitemchildlink {
    margin: 24px 116px 19px 0;
    font-family: Bitter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #242428;
  }
  .m-mainfooter__menuitem2 {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    margin-bottom: 18px;
    // grid-row-end: span 1 ;
  }
  .m-mainfooter__menuitem3 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: span 1;
  }
  .m-mainfooter__menuitem4 {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: span 1;
  }
  .m-footersidenavigation {
    display: block;

    // display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    // padding-top: 48px;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: span 1;
    // height: fit-content;
    // width: 100%;
    margin-bottom: 80px;
  }
  .m-footersocialsnavigation {
    // align-self: center;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: span 1;
  }
  .m-footermetanavigation {
    // align-self: center;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 5;
    grid-row-end: span 1;
  }
  .m-metafooter__menuitem {
    margin-right: 25px;
  }
}
