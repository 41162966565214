.m-news-detail__content {
  margin-bottom: 80px;
  @include media-breakpoint-up(lg) {
    @include make-col-offset(2);
    @include make-col(8);
    margin-bottom: 120px;
  }
}

.m-news-detail__hero-image {
  width: 100%;
}

.m-news-detail__figure {
  margin-bottom: 20px;
  figcaption {
    @extend %label;
    margin-top: 12px;
  }
}

.m-event-item__datetime--details {
  margin-bottom: 12px;
}

.m-news-detail__imageWrapper {
  position: relative;
}

.m-news-detail__description * {
  font-family: $roboto;
}

.m-news-details__authors {
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.m-news-detail__bodytext .external-link {
  display: block;
  @include media-breakpoint-up(lg) {
    margin-bottom: 120px;
  }
}

.m-news-detail__head,
.m-news-detail__bodytext,
.m-news-details__authors-headline,
.m-news-details__author {
  margin-bottom: 24px;
}

.m-news-detail__bodytext {
  @extend %text_format;
}
