.m-filteredarticle {
  &:last-child .m-filteredarticle__endseparator {
    @include make-col(12);
    border-bottom: 1px black solid;
    margin-bottom: 24px;
  }
}

.m-filteredarticle__separator {
  @include make-col(12);

  border-bottom: 1px black solid;
  margin-bottom: 24px;
}

.m-filteredarticle__creators{
  @include make-col(12);

  @extend %body-small;
  margin-bottom: 12px;
}

.m-filteredarticle__title {
  @include make-col(12);
  margin-bottom: 24px;
  @extend %h3;
}

.m-filteredarticle__title a {
  @extend %h3;
}

.m-filteredarticle__subheader {
  @extend %body;
  margin-bottom: 24px;
}

.m-filteredarticle__date {
  @extend %body-small;
  margin-bottom: 24px;
}

.m-filteredarticle__date:before {
  content: "";
  display: inline-block;
  width: 41px;
  height: 2px;
  margin-right: 8px;
  background-color: $greige;
  vertical-align: middle;
}
.m-filteredarticle__type {
  @extend %body-small;
  margin-bottom: 24px;
}

.m-filteredarticle__type:before {
  content: "";
  display: inline-block;
  width: 41px;
  height: 2px;
  margin-right: 8px;
  background-color: $greige;
  vertical-align: middle;
}