.m-frontpage-hero {
  @extend %ctype-base;
}

.m-frontpage-hero__container {
  @include media-breakpoint-up(md) {
    @include make-container();
    position: relative;
    max-width: map-get($container-max-widths, lg);
  }
}

.m-frontpage-hero__slide {
  @include media-breakpoint-up(md) {
    padding-bottom: 55px;
  }
}

.m-frontpage-hero__slide--text {
  padding-right: 78px;
  padding-left: 26px;
  padding-top: 36px;
  order: 2;
  position: relative;
  min-height: 330px;
  padding-bottom: $spacing-l;
  width: 100%;

  @include media-breakpoint-up(md) {
    @include make-col(5);
    padding-bottom: 92px;
    padding-top: 72px;
    padding-left: 40px;
    padding-right: 0;
    position: relative;
    z-index: 2;

    order: 1;
    min-height: unset;

    >* {
      background-color: rgba($greige-30, 50%);
    }
  }
}

.m-frontpage-hero__slide--title {
  font-weight: 100;
}

.m-frontpage-hero__slide--subline,
.m-frontpage-hero__slide--title {
  margin-bottom: $spacing-l;

  @include media-breakpoint-up(md) {
    margin-bottom: 36px;
  }
}

.m-frontpage-hero__slide--subline,
.m-frontpage-hero__slide--subline p {
  @extend %subline;
}

.m-frontpage-hero__slide--link {
  @extend %arrow-link;
}

.m-frontpage-hero__slide--imagecontainer {
  order: 1;
  width: 100%;

  @include media-breakpoint-up(md) {
    @include make-col(7);
    order: 2;
  }
}

.m-frontpage-hero__slide--imagewrapper {
  max-height: 390px;
  width: 100%;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    margin-left: auto;
    overflow: visible;
    position: relative;
    height: 100%;
    order: 2;
    max-width: 560px;
    max-height: 420px;
  }
}

.m-frontpage-hero__slide--image {
  height: 390px;
  width: 100%;

  object-fit: cover;

  @include media-breakpoint-up(md) {
    margin-left: auto;
    height: 100%;
    width: auto;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 135px 100%);
  }
}

.m-frontpage-hero__slide--inner {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  background-color: $greige-30;

  @include media-breakpoint-up(md) {
    overflow: visible;
    height: 420px;
  }
}

.m-frontpage-hero__slide--slash {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
    position: absolute;
    width: auto;
    z-index: 1;
    left: -23px;
  }
}

.m-frontpage-hero__slide--slash-mobile {

  position: absolute;
  right: 0;
  bottom: 190px;
  height: 288px;
  width: auto;

  @include media-breakpoint-up(md) {
    display: none;
  }
}


.swiper-pagination-bullets.swiper-pagination-clickable.m-frontpage-hero__slider--pagination {
  text-align: right;
  padding-top: $spacing-l;
  padding-right: 26px;

  .swiper-pagination-bullet {
    padding-top: 0;
    padding-right: 0;
    width: 12px;
    height: 12px;
    opacity: 1;
    background-color: $greige;
    transition: background-color 0.3s ease-in-out;

    &.swiper-pagination-bullet-active {
      background-color: $black;
    }
  }

  @include media-breakpoint-up(md) {
    position: absolute;
    padding-right: 0;
    z-index: 9;
    bottom: 20px;
    right: 0;
    left: unset;
    width: fit-content;
  }
}