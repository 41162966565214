.m-teaser-big {
  margin-bottom: 80px;
  &__image {
    object-fit: contain;
    object-position: top left;
  }
  &__image,
  &__title,
  &__description {
		margin-bottom: 24px;
  }
  &__link {
		@extend %arrow-link;
  }
  @include media-breakpoint-up(lg) {
		margin-bottom: 120px;
		&__image {
			margin-bottom: 0;
		}
    &__image-link {
      @include make-col(8);
      margin-bottom: 0;
    }
    &__text {
      @include make-col(4);
    }
  }
}
