.m-languagenavigation {
  @extend %label;

  margin-right: 20px;

  @include media-breakpoint-up(md) {
    margin-right: 44px;
  }
}
.m-languagenavigation__item {
  @extend %label;
  display: inline-block;
  text-transform: uppercase;
  a {
    font-weight: $regular;
  }

  &.active a {
    font-weight: $bold;
    border-bottom: 2px solid black;
  }
}
