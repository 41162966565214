.l-default__header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  height: 93px;
  @include media-breakpoint-up(lg) {
    margin-bottom: $spacing-l;
    height: unset;
  }
}

.l-default__branding,
.l-home__branding {
  margin-right: auto;
  @include media-breakpoint-up(lg) {
    padding-top: 9px;
    margin-bottom: -16px;
  }
  .l-default__logo,
  .l-home__logo {
    width: 153px;
    padding-right: 10px;
    @include media-breakpoint-up(lg) {
      width: 256px;
      padding-right: unset;
    }
  }
}

.l-home__headertop,
.l-default__headertop {
  display: flex;
  padding-top: 8px;
  padding-bottom: 11px;
  align-items: center;
  @include media-breakpoint-up(lg) {
    align-items: flex-end;
  }
}

.l-default__menu-toggle {
  position: relative;
  height: 23px;
  width: 36px;
  cursor: pointer;
  &--line {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: $black;
    transition: all 0.3s ease-in-out;
    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 11px;
    }
    &:nth-child(3) {
      top: 22px;
    }
  }

  &.is-active {
    .l-default__menu-toggle--line {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: 11px;
      }
      &:nth-child(2) {
        transform: rotate(-45deg);
        top: 11px;
      }
      &:nth-child(3) {
        opacity: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.l-home__headertop__nav,
.l-default__headertop__nav {
  @include media-breakpoint-up(lg) {
    padding-top: 49px;
    padding-bottom: 28px;
    display: flex;
    align-items: center;
  }
}

.l-default__headerbottom {
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.l-default__main * {
  scroll-margin-top: 93px;

  @include media-breakpoint-up(lg) {
    scroll-margin-top: 182px;
  }
}
