.m-searchbar {
  border-bottom: 1px solid black;
  display: flex;
  width: 150px;
}

.m-searchbar__input {
  @extend %label;
  border: none;
  padding: 5px 0;
  width: 100%;
}

.m-searchbar__label {
  flex: 1;
}

.m-searchbar__submit {
  background-color: transparent;
  border: none;
  flex: 0 0 32px;
}

.m-mainnavigation__wrapper {
  .m-searchbar {
    width: 100%;
    border-color: $white;
    margin-bottom: 21px;

    .m-searchbar__input {
      background-color: transparent;
      color: white;
      &::placeholder{
        color: white;
        opacity: 1;
      }
    }

    .m-searchbar__submit img {
      content: url('../images/icons/ic-search-white.svg');
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

.l-default__headertop__nav {
  .m-searchbar {
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }
}
