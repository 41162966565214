.m-news-all {
  @extend %ctype-base;

  .wzb-filteredArticle__link {
    display: block;
    transition: scale 0.2s ease-in-out;
    &:hover {
      scale: 1.01;
    }
  }

  .wzb-filters {
    display: flex;
    flex-flow: row wrap;
    column-gap: $grid-gutter-width;
    row-gap: $spacing-l;
    margin-bottom: 120px;
    @include media-breakpoint-up(lg) {
      margin-bottom: $spacing-xl;
    }
  }

  .wzb-filter {
    flex: 1 1 360px;
    position: relative;
    @include media-breakpoint-up(sm) {
      flex: 0 1 263px;
    }

    &.wzb-js-open {
      .wzb-filter__items {
        display: block;
      }

      .wzb-filter__head::after {
        transform: rotate(180deg);
      }
    }
  }

  .wzb-filter__head {
    border: 1px solid $black;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    white-space: nowrap;
    justify-content: space-between;
    cursor: pointer;

    &:after {
      content: '';
      @include sprite('icon-chevron');
      display: block;
      height: 24px;
      width: 24px;
      margin-left: 12px;
    }
  }

  .wzb-filter__items {
    display: none;
    border: 1px solid $black;
    position: absolute;
    background: $white;
    border-top: none;
    width: 100%;
    z-index: 10;
  }

  .wzb-filter__item {
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    .wzb-checkbox__checkbox {
      display: none;
    }

    .wzb-checkbox__label {
      padding: $spacing-m;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      &::after {
        content: '';
        height: 24px;
        width: 24px;
        display: block;
        @include sprite('icon-checkbox-inactive');
      }
    }
    :checked + .wzb-checkbox__label::after {
      @include sprite('icon-checkbox-active');
    }

    &:hover {
      background: $greige-30;
    }
  }

  .wzb-filteredArticle__link,
  a {
    &:hover {
      color: unset;
      text-decoration: none;
    }
  }

  .wzb-filteredArticle__head {
    margin-bottom: 16px;
  }

  .wzb-filteredArticle__copy {
    margin-bottom: $spacing-l;
  }

  .wzb-filteredArticle__date {
    @extend %small;
    display: block;
    padding-left: 49px;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 41px;
      height: 2px;
      background: $greige;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .wzb-filteredArticles__button {
    border: none;
    background: none;
    cursor: pointer;
    span {
      @extend %arrow-link;
    }
  }
}

.m-news-all__itemImage {
  margin-bottom: 16px;
}

.m-news-all__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 360px));
  column-gap: $grid-gutter-width;
  row-gap: $spacing-xl;
  margin-bottom: $spacing-xl;
}

.m-news-all__item {
  display: none;
  animation: fadeInFromBottom 0.5s ease-in-out;
  width: 100%;
  &.wzb-js-show {
    display: block;
  }
}

.m-news-all__itemCategories {
  margin-bottom: 16px;
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
}

.m-news-all__itemCategory {
  @extend %small;
  padding: 7px 12px;
  background-color: $greige-30;
}
