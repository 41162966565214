.m-tripleteaser {
  @extend %ctype-base;
}

.m-tripleteaser__wrapper {
  display: grid;

  padding: 0;
  list-style: none;
  gap: 48px;
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
}

.m-tripleteaser__teaser {
  list-style-type: none;

  &::before {
    content: none;
  }
}

.m-tripleteaser__title {
  margin-bottom: 24px;
}

.m-tripleteaser__text {
  margin-bottom: 24px;
}

.m-tripleteaser__link {
  @extend %arrow-link;

}
