.m-image {
  @extend %ctype-base;
  @extend .container;
  display: flex;
  justify-content: center;
}

.m-image__imageWrapper {
  position: relative;
  display: inline-block;
}

.wzb-image__image,
.m-image-text-media__image {
  max-width: 750px;
  margin: 0 auto;
}

.m-image__copyright,
.wzb-gallery__copyright {
  @extend %label;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 3px 12px;
  background: $greige;
}

.wzb-gallery__item {
  margin-bottom: $spacing-xl;
}
