.m-partnerelement {
  background-color: #4c5f76;
}
.m-partnerelementcontainer {
  position: relative;
  background-color: #4c5f76;
  overflow: hidden;
}
.partnerelementheader {
  @include make-container();
  z-index: 3;
  padding-top: 42px;
  color: white;
  max-width: map-get($container-max-widths, xs);
  margin-bottom: $spacing-xl;

  @include media-breakpoint-up(lg) {
    max-width: map-get($container-max-widths, lg);
    margin-bottom: 60px;
  }
}

.logoscontainer {
  @include make-container();
  z-index: 5;
  position: relative;
  max-width: map-get($container-max-widths, xs);
  @include media-breakpoint-up(lg) {
    max-width: map-get($container-max-widths, lg);
  }
}

.logoscontainercontent__wrapper {
  background-color: $white;
}

.logoscontainercontent {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 150px;
  background-color: $dusk-blue;

  grid-gap: 1px;
  & > div {
    background-color: white;
    padding: 10px;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.partnerlogo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.partnerlogoimg {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.topbackgroundcontainer {
  position: relative;
}
.topbackground {
  position: absolute;
  background-color: #c9cfd6;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 254px;
  z-index: 1;
  clip-path: polygon(0 56px, 100% 177px, 100% 100%, 0% 100%);
}
