.m-contact-people {
  @extend %ctype-base;
}

.m-contact-people__item {
  @include make-col(12);
  margin-bottom: 48px;
  //last element
  &:nth-last-child(-n + 1){
    margin-bottom: 0px;
  }


  @include media-breakpoint-up(lg) {
    @include make-col(4);
    margin-bottom: 60px;
    
    //last 3 elements
    &:nth-last-child(-n + 3){
      margin-bottom: 0px;
    }
  }
}

.m-contact-people__head {
  @extend %h2;
  margin-bottom: 48px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 60px;
  }
}

.m-contact-person__info {
  @extend %label;
}

.m-contact-person__head {
  @extend %h4;
  padding: 16px 0;
}

.m-contact-person__link {
  visibility: hidden;
}
