.m-event-list {
  @extend %ctype-base;
}

.m-event-list__header {
  margin-bottom: 24px;
}

.m-event-item {
  padding: 24px 0;
}

.m-event-item:not(.m-event_has-image) {
  border-top: 1px solid $black;
}

.m-event-item.hidden {
  display: none !important;
}

.m-event-item__image-link {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 16px;
}

.m-event-item__image {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: top left;
}

.m-event-item__header {
  margin-bottom: 16px;
}

.m-event-item__date {
  @extend %display;
  margin-bottom: 12px;
}

.m-event-item__time {
  margin-bottom: 16px;
}

.m-event-item__category {
  display: inline-block;
  width: fit-content;
  background-color: $greige-30;
  padding: 7px 21px 7px 12px;
  margin-bottom: 16px;
  margin-right: 16px;
}

.m-event-list__show-more {
  margin-top: 24px;
  cursor: pointer;
  &::before {
    @include sprite('icon-chevron');
    content: '';
    position: relative;
    top: 5px;
    height: 25px;
    width: 25px;
    display: inline-block;
  }
  &.hidden {
    display: none;
  }
}

.m-event-list-filtered {
  margin-bottom: 24px;
}

@include media-breakpoint-up(lg) {
  .m-event-item__image {
    margin-bottom: unset;
  }
  .m-event-item:not(.m-event_has-image) {
    .m-event-item__text-container {
      display: flex;
      justify-content: space-between;
    }
    .m-event-item__datetime {
      @include make-col(3);
    }
    .m-event-item__content {
      @include make-col(9);
    }
    .m-event-item__time {
      display: grid;
    }
    .m-event-item__time-separator {
      display: none;
    }
  }
  .m-event_has-image {
    display: flex;
    justify-content: space-between;
    .m-event-item__image-link {
      @include make-col(5);
    }
    .m-event-item__text-container {
      width: auto;
      margin-left: 29px;
    }
    .m-event-item__time {
      margin-bottom: 16px;
    }
  }
}
